<!-- ----------------------------------------------------------------------- -->
<!--
name    : USERS VIEW

type    : view

route   : /users
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="users-view">
    <!-- router-view will be replaced with whichever route is active -->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'UsersView',
    components: {},
  };
</script>
